import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styles from './scholarship.module.css'
import SectionHeader from './section-header.js'

export default ({ data }) => (

  <div className='wrapper'>
    <div className={styles.section}>
      <h3>Providing aftercare for those in need</h3>

      <p>The Ben Meyer Recovery Scholarship is the centerpiece of our charity work. This award provides scholarship funds for trusted sober living programs for people who can’t afford this essential part of addiction recovery. </p>
    </div>
    <div className={styles.section}>
      <h3>Why focus on aftercare?</h3>
      <div className={styles.infoBlock}>
        <div className={styles.statisticBlock}>
          <p className={styles.statistic}>20</p>
          <span className={styles.statisticText}>million</span>
        </div>
        <p className={styles.detail}>Addiction in America impacts the lives of over 20 million Americans, and 18 million of those go untreated.  Of those that do receive treatment, the majority do not see successful treatment outcomes. These low success rates are a direct result of the lack of effective tools to manage life after the typical 28-day inpatient treatment ends.</p>
      </div>

      <div className={styles.infoBlock}>
        <div className={styles.statisticBlock}>
          <p className={styles.statistic}>28</p>
          <span className={styles.statisticText}>days</span>
        </div>
        <p className={styles.detail}>For those who do undergo treatment, most insurance plans only cover 28 days of rehab. But individuals who return home after a 28-day treatment to the same groups of friends and the same triggers maintain a lower rate of sobriety than clients who join “aftercare” programs. Studies suggest that sobriety rates for individuals who transition to a sober living facility are 40% better than for those who don't.</p>
      </div>

      <div className={styles.infoBlock}>
        <div className={styles.statisticBlock}>
          <p className={styles.statistic}>18</p>
          <span className={styles.statisticText}>months</span>
        </div>
        <p className={styles.detail}>Health experts estimate that it can take up to 18 months for the brain to fully recover from the effects of addiction. Drug use can structurally damage the brain's dopamine system and prefrontal cortex. Even after recovering, psychological effects from drug use can persist. The combination of sober living transitional housing with an intensive outpatient program has proven to be an excellent model for empowering clients with the tools necessary for a successful life in recovery.</p>
      </div>

      <p>Comprehensive, quality sober living programs are very costly and often unattainable for many as insurance providers will not cover the cost of stay.  With the Ben Meyer Recovery Scholarship, we can now provide financially challenged individuals a real chance at long-term recovery by providing them access to a sober living facility with a community of like-minded adults that share in this same pursuit.</p>

      <h3>About sober living facilities</h3>

      <p>A sober living facility or “sober living house” is a drug and alcohol-free housing environment that facilitates transitioning back to everyday life by providing individuals in recovery a safe and supportive place to heal, away from outside pressures.  During this time, intensive outpatient therapy continues for the client with a focus on cognitive behavioral therapy, where individuals learn about their triggers and new methods of dealing with them.</p>
      <p>Sober living facilities enchance the prospect of a successful recovery by offering residents:</p>
      <ul>
        <li><span style={{fontWeight:'bold', fontSize:'1.2em'}}>Stability: </span>Sober Living establishes a bridge between treatment and everyday life, offering an opportunity to stabilize relationships, employment or other aspects of life that need rebuilding.</li>
        <li><span style={{fontWeight:'bold', fontSize:'1.2em'}}>Sobriety: </span>Residents are required to stay sober, dramatically reducing the risk of relapse.</li>
        <li><span style={{fontWeight:'bold', fontSize:'1.2em'}}>Accountability: </span>Residents in sober living communities are given responsibilities that help develop accountability.</li>
        <li><span style={{fontWeight:'bold', fontSize:'1.2em'}}>Connections: </span>Sober living housing provides opportunities to make new connections with people in recovery and build meaningful friendships with others who are committed to sobriety.</li>
        <li><span style={{fontWeight:'bold', fontSize:'1.2em'}}>Skill-building: </span>Sober Living provides an environment to practice and grow recovery skills learned in treatment.</li>
        <li><span style={{fontWeight:'bold', fontSize:'1.2em'}}>Time: </span>Being free from outside pressures of old environments allows for more freedom to focus on healing.</li>
      </ul>


    </div>
  </div>
)
