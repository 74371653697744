import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ContentBlock from '../components/content-block'
import Scholarship from '../components/scholarship'
import SectionHeader from '../components/section-header';

class ScholarshipPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const headerData = {
      title: 'The Ben Meyer Recovery Scholarship'
    }

    return (
      <Layout location={this.props.location}>
          <Helmet title={siteTitle} />
          <SectionHeader data={headerData} />
          <Scholarship />
      </Layout>
    )
  }
}

export default ScholarshipPage

export const pageQuery = graphql`
  query ScholarshipQuery {
    allContentfulContentBlock(sort: {order: ASC, fields: order} filter: {page: {eq:"/scholarship"}}) {
    edges {
      node {
        backgroundImage {
          file {
            url
          }
        }
        blockImage {
          file {
            url
          }
        }
        contentText {
          contentText
        }
        contentful_id
        title
        link
        linkText
        order
        page
        imagePosition
      }
    }
  }

  allContentfulAsset(filter: {contentful_id: {eq: "7aMyX9u58lkIai4l271R0a"}}) {
  edges {
    node {
      file {
        url
      }
      contentful_id
    }
  }
}
  }
`
